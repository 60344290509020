import { Inject, Injectable, Signal, WritableSignal, effect, inject, signal, untracked } from '@angular/core';
import { SESSIONS_PATH, TABLES_PATH, VENUES_PATH } from '../constants';
import { areUpdatesValid, mergeUpdatesToInitialData } from '../helpers';
import { IUserVenueAndTableParams } from '../interfaces';
import { SessionModelRequestDto, SessionModelResponseDto } from '../models';
import { ApiService } from './api.service';
import { UserAccountService } from './user-account.service';
import { VenueService } from './venue.service';
import { SESSIONS_WEBSOCKET_SERVICE, WebsocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class SessionsService {
  readonly #apiService = inject(ApiService<SessionModelRequestDto, SessionModelResponseDto>);
  readonly #venueService = inject(VenueService);
  readonly #userAccountService = inject(UserAccountService);

  readonly #venueSessions: WritableSignal<SessionModelResponseDto[]> = signal([]);

  // Exposed signals (read-only)
  protected readonly venueSessionsMessages = this.sessionsWebsocketService.initialMessages;
  protected readonly venueSessionsUpdates = this.sessionsWebsocketService.updates;
  readonly venueSessions: Signal<SessionModelResponseDto[] | undefined> = this.#venueSessions.asReadonly();

  constructor(
    @Inject(SESSIONS_WEBSOCKET_SERVICE)
    private readonly sessionsWebsocketService: WebsocketService<SessionModelResponseDto>,
  ) {
    effect(() => {
      const venueId = this.#venueService.venueId();

      if (venueId) {
        const params = {
          userId: this.#userAccountService.userJWTDetails()?.sub,
          venueId: this.#venueService.venueId(),
          tableId: this.#venueService.tableId(),
        } as IUserVenueAndTableParams;

        untracked(() => {
          this.sessionsWebsocketService.sendInitialParams(params);
        });
      }
    });

    effect(
      () => {
        const venueSessionsUpdates = this.venueSessionsUpdates();
        const venueSessionsMessages = this.venueSessionsMessages();

        if (!venueSessionsMessages) return;

        if (this.venueSessions()?.length === 0) {
          this.#venueSessions.set(venueSessionsMessages);
        }

        if (!areUpdatesValid(venueSessionsUpdates)) return;

        this.#venueSessions.update(sessions => mergeUpdatesToInitialData(sessions, venueSessionsUpdates));

        this.resetConsumedUpdates();
      },
      { allowSignalWrites: true },
    );
  }

  async acceptUser(userId: string, sessionId: string) {
    await this.#apiService.post(`${SESSIONS_PATH}/${sessionId}/users/accept`, { id: userId } as unknown as SessionModelRequestDto);
  }

  async declineUser(userId: string, sessionId: string) {
    await this.#apiService.post(`${SESSIONS_PATH}/${sessionId}/users/decline`, { id: userId } as unknown as SessionModelRequestDto);
  }

  async acceptUserByProvidingVenueAndTable(userId: string, venueId: string, tableId: string) {
    await this.#apiService.post(`${SESSIONS_PATH}/${venueId}/${tableId}/users/accept`, {
      id: userId,
    } as unknown as SessionModelRequestDto);
  }

  async declineUserByProvidingVenueAndTable(userId: string, venueId: string, tableId: string) {
    await this.#apiService.post(`${SESSIONS_PATH}/${venueId}/${tableId}/users/decline`, {
      id: userId,
    } as unknown as SessionModelRequestDto);
  }

  async deleteSessionByVenueAndTable(venueId: string, tableId: string) {
    return this.#apiService.delete(`${VENUES_PATH}/${venueId}/${TABLES_PATH}/${tableId}/${SESSIONS_PATH}`);
  }

  setSessions(sessions: SessionModelResponseDto[]) {
    this.#venueSessions.set(sessions);
  }

  resetConsumedUpdates() {
    this.sessionsWebsocketService.resetConsumedUpdates();
  }
}
