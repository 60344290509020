import { effect, Inject, inject, Injectable, untracked } from '@angular/core';
import { SessionsService, SUBORDERS_WEBSOCKET_SERVICE, VenueService, WebsocketService } from '.';
import { ORDERS_PATH, SUBORDERS_PATH, TABLES_PATH, VENUES_PATH } from '../constants';
import { IVenueAndTableParams } from '../interfaces';
import { SuborderModelRequestDto, SuborderModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SubordersService {
  readonly #apiService = inject(ApiService<SuborderModelRequestDto, SuborderModelResponseDto>);
  readonly #venueService = inject(VenueService);
  readonly #sessionsService = inject(SessionsService);

  // Exposed signals (read-only)
  readonly venueSessionsMessages = this.subordersWebsocketService.initialMessages;
  readonly venueSessionsUpdates = this.subordersWebsocketService.updates;
  readonly orderId = this.#sessionsService.venueSessions()?.[0]?.order._id;

  constructor(
    @Inject(SUBORDERS_WEBSOCKET_SERVICE)
    private readonly subordersWebsocketService: WebsocketService<SuborderModelResponseDto>,
  ) {
    effect(() => {
      const venueId = this.#venueService.venueId();

      if (venueId) {
        const params = {
          venueId: this.#venueService.venueId(),
          tableId: this.#venueService.tableId(),
        } as IVenueAndTableParams;

        untracked(() => {
          this.subordersWebsocketService.sendInitialParams(params);
        });
      }
    });
  }

  getSubordersByOrderId(orderId: string): Promise<SuborderModelResponseDto[]> {
    return this.#apiService.getAll(`${ORDERS_PATH}/${orderId}/${SUBORDERS_PATH}`);
  }

  createSuborder(suborderItem: SuborderModelRequestDto): Promise<SuborderModelResponseDto> {
    return this.#apiService.post(SUBORDERS_PATH, suborderItem);
  }

  deleteTableSuborders(venueId: string, tableId: string) {
    return this.#apiService.delete(`${VENUES_PATH}/${venueId}/${TABLES_PATH}/${tableId}/${SUBORDERS_PATH}`);
  }
}
