export interface DecimalPrecision {
  round: (num: number, decimalPlaces?: number) => number;
  ceil: (num: number, decimalPlaces?: number) => number;
  floor: (num: number, decimalPlaces?: number) => number;
  trunc: (num: number, decimalPlaces?: number) => number;
  toFixed: (num: number, decimalPlaces?: number) => string;
}

export const DecimalPrecision2: DecimalPrecision = (function () {
  return {
    round: function (num: number, decimalPlaces?: number) {
      const p = Math.pow(10, decimalPlaces || 0);
      const n = num * p * (1 + Number.EPSILON);
      return Math.round(n) / p;
    },
    ceil: function (num: number, decimalPlaces?: number) {
      const p = Math.pow(10, decimalPlaces || 0);
      const n = num * p * (1 - Math.sign(num) * Number.EPSILON);
      return Math.ceil(n) / p;
    },
    floor: function (num: number, decimalPlaces?: number) {
      const p = Math.pow(10, decimalPlaces || 0);
      const n = num * p * (1 + Math.sign(num) * Number.EPSILON);
      return Math.floor(n) / p;
    },
    trunc: function (num: number, decimalPlaces?: number) {
      return (num < 0 ? this.ceil : this.floor)(num, decimalPlaces);
    },
    toFixed: function (num: number, decimalPlaces?: number) {
      return this.round(num, decimalPlaces).toFixed(decimalPlaces);
    },
  };
})();

// Testing scenarios
// test rounding of half
// console.log(DecimalPrecision2.round(0.5)); // 1
// console.log(DecimalPrecision2.round(-0.5)); // -1

// testing very small numbers
// console.log(DecimalPrecision2.ceil(1e-8, 2) === 0.01);
// console.log(DecimalPrecision2.floor(1e-8, 2) === 0);

// testing simple cases
// console.log(DecimalPrecision2.round(5.12, 1) === 5.1);
// console.log(DecimalPrecision2.round(-5.12, 1) === -5.1);
// console.log(DecimalPrecision2.ceil(5.12, 1) === 5.2);
// console.log(DecimalPrecision2.ceil(-5.12, 1) === -5.1);
// console.log(DecimalPrecision2.floor(5.12, 1) === 5.1);
// console.log(DecimalPrecision2.floor(-5.12, 1) === -5.2);
// console.log(DecimalPrecision2.trunc(5.12, 1) === 5.1);
// console.log(DecimalPrecision2.trunc(-5.12, 1) === -5.1);

// testing edge cases for round
// console.log(DecimalPrecision2.round(1.005, 2) === 1.01);
// console.log(DecimalPrecision2.round(39.425, 2) === 39.43);
// console.log(DecimalPrecision2.round(-1.005, 2) === -1.01);
// console.log(DecimalPrecision2.round(-39.425, 2) === -39.43);

// testing edge cases for ceil
// console.log(DecimalPrecision2.ceil(9.13, 2) === 9.13);
// console.log(DecimalPrecision2.ceil(65.18, 2) === 65.18);
// console.log(DecimalPrecision2.ceil(-2.26, 2) === -2.26);
// console.log(DecimalPrecision2.ceil(-18.15, 2) === -18.15);

// testing edge cases for floor
// console.log(DecimalPrecision2.floor(2.26, 2) === 2.26);
// console.log(DecimalPrecision2.floor(18.15, 2) === 18.15);
// console.log(DecimalPrecision2.floor(-9.13, 2) === -9.13);
// console.log(DecimalPrecision2.floor(-65.18, 2) === -65.18);

// testing edge cases for trunc
// console.log(DecimalPrecision2.trunc(2.26, 2) === 2.26);
// console.log(DecimalPrecision2.trunc(18.15, 2) === 18.15);
// console.log(DecimalPrecision2.trunc(-2.26, 2) === -2.26);
// console.log(DecimalPrecision2.trunc(-18.15, 2) === -18.15);

// testing round to tens and hundreds
// console.log(DecimalPrecision2.round(1262.48, -1) === 1260);
// console.log(DecimalPrecision2.round(1262.48, -2) === 1300);

// testing toFixed()
// console.log(DecimalPrecision2.toFixed(1.005, 2) === '1.01');
