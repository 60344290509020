import { CommonModule, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  Signal,
  ViewChild,
  WritableSignal,
  computed,
  inject,
  model,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { fadeAnimation } from 'src/app/animations';
import { debounce, stopPropagation } from 'src/app/helpers';
import {
  CartItemModelRequestDto,
  CartItemModelResponseDto,
  CategoryModelResponseDto,
  ProductModelResponseDto,
  SizeModelResponseDto,
  VenueModelResponseDto,
} from 'src/app/models';
import { PresentationCasePipe } from 'src/app/pipes';
import { CartService, LocalesService, UserAccountService, VenueMenuItemModalService } from 'src/app/services';
import { VenueService } from 'src/app/services/venue.service';
import { CART_ITEMS_WEBSOCKET_SERVICE, WebsocketService } from 'src/app/services/websocket.service';
import { AppModalComponent } from '../app-modal/app-modal.component';
import { WaytrIcon } from '../waytr-icon';
/* eslint-disable @angular-eslint/component-selector */

@Component({
  selector: 'venue-menu-item-modal',
  standalone: true,
  templateUrl: './venue-menu-item-modal.component.html',
  styleUrls: ['./venue-menu-item-modal.component.scss'],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    AppModalComponent,
    NzSkeletonModule,
    TranslateModule,
    NgOptimizedImage,
    WaytrIcon,
    PresentationCasePipe,
    CurrencyPipe,
  ],
})
export class VenueMenuItemModalComponent {
  readonly #venueMenuItemModalService = inject(VenueMenuItemModalService);
  readonly #cartService = inject(CartService);
  readonly #venueService = inject(VenueService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #userAccountService = inject(UserAccountService);
  readonly #localesService = inject(LocalesService);

  isModalVisible = model(false);
  protected isModalClosed = output<boolean>();
  @ViewChild('appModal') protected appModal!: ElementRef;

  protected stopPropagation = stopPropagation;

  readonly #cartItems: Signal<CartItemModelResponseDto[]> = this.cartItemsWebsocketService.initialMessages;
  readonly #venue: Signal<VenueModelResponseDto | undefined> = this.#venueService.venue;
  protected readonly menuItemModal: Signal<ProductModelResponseDto | undefined> = this.#venueMenuItemModalService.venueMenuItemModal;
  protected readonly menuItemModalAllergens = this.#venueMenuItemModalService.venueMenuItemModalAllergens;
  protected readonly menuItemModalIngredients = this.#venueMenuItemModalService.venueMenuItemModalIngredients;
  protected readonly currentLocale = this.#localesService.currentLocale;

  protected selectedSizeId = '';
  protected animation: WritableSignal<boolean> = signal(false);
  protected elementLoaded = true;

  protected menuItemModalSizes = computed(() => {
    const sizes = this.#venueMenuItemModalService.venueMenuItemModalSizes();
    // return sorted sizes based on price
    const sortedSizes = sizes?.sort((a, b) => a.price - b.price);
    this.selectedSizeId = sortedSizes?.find(size => size.selectedByDefault)?._id || '';
    return sortedSizes;
  });
  protected cartItem = computed(() => this.getCartItem(this.#cartItems(), this.menuItemModal()));
  protected category = computed(() => this.getCategory(this.#venue(), this.menuItemModal()));

  constructor(
    @Inject(CART_ITEMS_WEBSOCKET_SERVICE)
    readonly cartItemsWebsocketService: WebsocketService<CartItemModelResponseDto>,
  ) {
    toObservable(this.menuItemModal)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(newItem => {
        if (newItem) {
          this.elementLoaded = true;
        }
      });
  }

  private getCartItem(
    cartItems: CartItemModelResponseDto[],
    menuItemModal: ProductModelResponseDto | undefined,
  ): CartItemModelResponseDto | undefined {
    return cartItems?.find(cartItem => cartItem.product?._id === menuItemModal?._id);
  }

  private getCategory(
    venue: VenueModelResponseDto | undefined,
    menuItemModal: ProductModelResponseDto | undefined,
  ): CategoryModelResponseDto | undefined {
    return venue?.categories.find(category => category.products?.find(product => product._id === menuItemModal?._id));
  }

  closeModal() {
    this.isModalVisible.set(false);
    this.#venueMenuItemModalService.setMenuItemModal(undefined);
    this.isModalClosed.emit(true);
  }

  selectSize(event: MouseEvent | TouchEvent | KeyboardEvent, size: SizeModelResponseDto) {
    this.stopPropagation(event);
    this.selectedSizeId = size._id;
  }

  @debounce(150)
  changeQuantity(event: MouseEvent | TouchEvent | KeyboardEvent, addOrRemove: number) {
    this.stopPropagation(event);

    const product = this.menuItemModal();
    const cartItem = this.cartItem();

    const changedQuantity = (cartItem?.quantity || 0) + addOrRemove;

    if (cartItem) {
      if (changedQuantity > 0) {
        this.#cartService.changeQuantity(cartItem._id, cartItem.quantity + addOrRemove);
      } else {
        this.#cartService.removeCartItem(cartItem._id);
      }
    } else {
      if (changedQuantity < 0) {
        return;
      }

      // TODO: Add selected sizes to this object
      const venueId = this.#venueService.venueId();
      const tableId = this.#venueService.tableId();
      const userId = this.#userAccountService.userJWTDetails()?.sub;

      if (product && venueId && tableId) {
        const cartItem: CartItemModelRequestDto = {
          localizedFields: product.localizedFields,
          quantity: 1,
          selectedSizes: [],
          product: product._id,
          venue: venueId,
          table: tableId,
          // TODO: Get device and user from the context
          device: '62fbbc1310e10eaa32775ee5',
          user: userId,
        };

        this.#cartService.addCartItems([cartItem]).then(() => {
          this.closeModal();
        });
      }
    }
  }
}
