import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { en_US, NzI18nInterface, NzI18nService, ro_RO } from 'ng-zorro-antd/i18n';
import { ENGLISH_US, LOCALES_PATH, ROMANIAN } from '../constants';
import { LocalesResponse } from '../interfaces';
import { CountryModelRequestDto, CountryModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class LocalesService {
  readonly #apiService = inject(ApiService<CountryModelRequestDto, CountryModelResponseDto>);
  readonly #translateService = inject(TranslateService);

  // Make sources private so it's not accessible from the outside
  readonly #localesSource: WritableSignal<LocalesResponse | undefined> = signal(undefined);
  readonly #currentLocale: WritableSignal<string> = signal(ROMANIAN);

  // Exposed signals (read-only)
  readonly locales = this.#localesSource.asReadonly();
  readonly currentLocale = this.#currentLocale.asReadonly();
  // Make sources private so it's not accessible from the outside

  constructor(private i18n: NzI18nService) {}

  getAllLocales(): Promise<LocalesResponse> {
    return this.#apiService.getAll(`${LOCALES_PATH}`) as unknown as Promise<LocalesResponse>;
  }

  setAllLocales(locales: LocalesResponse): void {
    this.#localesSource.set(locales);
  }

  setLocale(locale: string): void {
    this.#translateService.use(locale);
    this.#currentLocale.set(locale);

    let ngZorroLocale: NzI18nInterface = en_US;

    switch (locale) {
      case ROMANIAN:
        ngZorroLocale = ro_RO;
        break;
      case ENGLISH_US:
        ngZorroLocale = en_US;
        break;
    }

    this.i18n.setLocale(ngZorroLocale);
  }
}
