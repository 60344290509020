import { CommonModule, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { addAnimation, removeAnimation } from 'src/app/animations';
import { stopPropagation } from 'src/app/helpers';
import { CartItemModelResponseDto } from 'src/app/models';
import { PresentationCasePipe } from 'src/app/pipes';
import { LocalesService } from 'src/app/services';
import { AppBasicComponent } from '../app-basic/app-basic.component';
import { WaytrIcon } from '../waytr-icon';
/* eslint-disable @angular-eslint/component-selector */

@Component({
  selector: 'venue-cart-item',
  standalone: true,
  imports: [
    CommonModule,
    AppBasicComponent,
    NzSkeletonModule,
    NgOptimizedImage,
    WaytrIcon,
    PresentationCasePipe,
    TranslateModule,
    CurrencyPipe,
  ],
  templateUrl: './venue-cart-item.component.html',
  styleUrls: ['./venue-cart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [addAnimation, removeAnimation],
})
export class VenueCartItemComponent {
  readonly #localesService = inject(LocalesService);

  item = input.required<CartItemModelResponseDto>();
  showInstructions = input(false);
  protected imageClickedEvent = output<boolean>();
  protected cardClickedEvent = output<boolean>();
  protected cardRemovedEvent = output<boolean>();
  protected changeQuantityEvent = output<number>();
  protected changeInstructionsEvent = output<string>();

  protected stopPropagation = stopPropagation;

  protected readonly currentLocale = this.#localesService.currentLocale;

  protected elementLoaded = true;

  get totalPrice(): number {
    const cartItem = this.item();

    if (cartItem && cartItem.productPriceSnapshot) {
      return cartItem.quantity * cartItem.productPriceSnapshot.finalPrice;
    }

    return 0;
  }

  imageClicked(event: MouseEvent | TouchEvent | KeyboardEvent) {
    stopPropagation(event);
    this.imageClickedEvent.emit(true);
  }

  cardClicked(event: MouseEvent | TouchEvent | KeyboardEvent) {
    stopPropagation(event);
    this.cardClickedEvent.emit(true);
  }

  cardRemoved(event: MouseEvent | TouchEvent | KeyboardEvent) {
    stopPropagation(event);
    this.cardRemovedEvent.emit(true);
  }

  changeQuantity(event: MouseEvent | TouchEvent | KeyboardEvent, removeOrAdd: number) {
    stopPropagation(event);
    const cartItem = this.item();

    if (cartItem?.quantity) {
      const quantity = cartItem.quantity + removeOrAdd;
      this.changeQuantityEvent.emit(quantity);
    }
  }

  changeInstructions(event: Event) {
    this.changeInstructionsEvent.emit((event.target as HTMLTextAreaElement).value);
  }
}
