import { Inject, Injectable, InjectionToken, Signal, signal, WritableSignal } from '@angular/core';
import { throwError } from 'rxjs';
import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { DatabaseOperations } from '../constants';
import { GatewayResponseModel, WaytrBaseIdModel, WaytrBaseTimestampModel, WebsocketEventsEnum, WebsocketMessagesEnum } from '../models';

export const WEBSOCKET_PATH = new InjectionToken<string>('');
export const APP_UPDATES_WEBSOCKET_SERVICE = new InjectionToken<string>('AppUpdatesWebsocketService');
export const CART_ITEMS_WEBSOCKET_SERVICE = new InjectionToken<string>('CartItemsWebsocketService');
export const ORDERS_WEBSOCKET_SERVICE = new InjectionToken<string>('OrdersWebsocketService');
export const SUBORDERS_WEBSOCKET_SERVICE = new InjectionToken<string>('SubordersWebsocketService');
export const SESSIONS_WEBSOCKET_SERVICE = new InjectionToken<string>('SessionsWebsocketService');
export const NOTIFICATIONS_WEBSOCKET_SERVICE = new InjectionToken<string>('NotificationsWebsocketService');
export const WAITER_COMMANDS_WEBSOCKET_SERVICE = new InjectionToken<string>('WaiterCommandsWebsocketService');
export const WAITER_COMMANDS_VENUES_WEBSOCKET_SERVICE = new InjectionToken<string>('WaiterCommandsVenuesWebsocketService');
export const VENUE_WEBSOCKET_SERVICE = new InjectionToken<string>('VenueWebsocketService');

@Injectable({ providedIn: 'root' })
export class WebsocketService<T extends WaytrBaseIdModel | WaytrBaseTimestampModel> {
  #websocket!: Socket;
  #initialParams: unknown;
  #reconnectTries = 0;
  readonly #options: Partial<ManagerOptions & SocketOptions> = {
    transports: ['websocket', 'polling'],
    rememberUpgrade: true,
  };

  // Make sources private so it's not accessible from the outside
  readonly #initialMessagesSource: WritableSignal<T[]> = signal([]);
  readonly #updatesSource: WritableSignal<T[]> = signal([]);
  readonly #socketIdSource: WritableSignal<string | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly initialMessages: Signal<T[]> = this.#initialMessagesSource.asReadonly();
  readonly updates: Signal<T[]> = this.#updatesSource.asReadonly();
  readonly socketId: Signal<string | undefined> = this.#socketIdSource.asReadonly();

  constructor(@Inject(WEBSOCKET_PATH) private readonly namespace: string) {
    this.createWebsocket();
  }

  private createWebsocket() {
    this.#websocket = io(`${environment.WS_URL}/${this.namespace}`, this.#options);
    this.#websocket.on(WebsocketEventsEnum.CONNECT, () => this.handleConnection());
    this.#websocket.once(WebsocketEventsEnum.DISCONNECT, reason => this.onDisconnect(reason));
    this.#websocket.on(WebsocketMessagesEnum.INIT, message => this.handleInitialState(message));
    this.#websocket.on(WebsocketMessagesEnum.DATA, message => this.handleNewState(message));
    this.#websocket.io.on(WebsocketEventsEnum.ERROR, error => this.onError(error));
    this.#websocket.io.on(WebsocketEventsEnum.RECONNECT, attempt => this.onReconnect(attempt));
    this.#websocket.on(WebsocketEventsEnum.CONNECT_ERROR, () => this.onConnectionError());
  }

  public sendMessage(eventName: string, message?: unknown) {
    this.#websocket.emit(eventName, message);
  }

  public sendInitialParams(message: unknown) {
    this.sendMessage(WebsocketMessagesEnum.PARAMS, message);
    this.#initialParams = message;
  }

  public closeWebSocket() {
    this.#websocket.disconnect();
    this.#websocket.close();
  }

  public resetMessages() {
    this.#initialMessagesSource.set([]);
    this.#updatesSource.set([]);
  }

  public resetConsumedUpdates() {
    this.#updatesSource.set([]);
  }

  private handleConnection() {
    this.#socketIdSource.set(this.#websocket.id);
    this.#reconnectTries = 0;
  }

  private handleInitialState(data: T[]) {
    this.#initialMessagesSource.set(data);
  }

  private handleNewState(data: GatewayResponseModel<T>) {
    this.#initialMessagesSource.update(values => this.handleUpdate(data, values));
    this.#updatesSource.update(values => [...values, data?.document]);
  }

  private handleUpdate(data: GatewayResponseModel<T>, values: T[]) {
    switch (data?.operation) {
      case DatabaseOperations.INSERT:
        values = values.concat(data?.document);
        break;
      case DatabaseOperations.DELETE:
        values = values.filter(val => val._id !== data?.document?._id);
        break;
      case DatabaseOperations.UPDATE: {
        return values.map(val => (val._id === data?.document?._id ? data?.document : val));
      }
    }

    return values;
  }

  private onDisconnect(reason: Socket.DisconnectReason) {
    console.error('WEBSOCKET DISCONNECT REASON', reason);

    if (reason === 'io server disconnect') {
      // The disconnection was initiated by the server, you need to reconnect manually
      this.#websocket.connect();
    } else if (reason === 'transport error') {
      /* empty */
    } else if (reason === 'transport close') {
      // Close old connection
      this.closeWebSocket();

      if (this.#reconnectTries < 5) {
        // Attempt to reconnect to the server after a short delay
        setTimeout(
          () => {
            this.createWebsocket();
            this.#reconnectTries++;
            this.sendInitialParams(this.#initialParams);
          },
          5000 + this.#reconnectTries * 1000 * 5,
        );
      }
    }
    // Else the socket will automatically try to reconnect
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onError(message: any) {
    throwError(() => console.error(message?.message));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onReconnect(message: unknown) {
    // console.error(message);
  }

  private onConnectionError() {}
}
