import { Inject, Injectable, effect, inject, untracked } from '@angular/core';
import { WAITER_COMMANDS_PATH } from '../constants';
import { IUserVenueAndTableParams } from '../interfaces';
import { WaiterCommandModelRequestDto, WaiterCommandModelResponseDto } from '../models';
import { ApiService } from './api.service';
import { VenueService } from './venue.service';
import { WAITER_COMMANDS_WEBSOCKET_SERVICE, WebsocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class WaiterCommandsService {
  readonly #apiService = inject(ApiService<WaiterCommandModelRequestDto, WaiterCommandModelResponseDto>);
  readonly #venueService = inject(VenueService);

  // Exposed signals (read-only)
  readonly waiterCommandsMessages = this.waiterCommandsWebsocketService.initialMessages;
  readonly waiterCommandsUpdates = this.waiterCommandsWebsocketService.updates;

  constructor(
    @Inject(WAITER_COMMANDS_WEBSOCKET_SERVICE)
    private readonly waiterCommandsWebsocketService: WebsocketService<WaiterCommandModelResponseDto>,
  ) {
    effect(() => {
      const venueId = this.#venueService.venueId();

      if (venueId) {
        const params = {
          venueId: this.#venueService.venueId(),
          tableId: this.#venueService.tableId(),
        } as IUserVenueAndTableParams;

        untracked(() => {
          this.waiterCommandsWebsocketService.sendInitialParams(params);
        });
      }
    });
  }

  async sendWaiterCommand(request: WaiterCommandModelRequestDto) {
    await this.#apiService.post(WAITER_COMMANDS_PATH, request);
  }
}
