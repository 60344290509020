<app-basic-component>
  <ng-template #template>
    @if (item(); as item) {
      <div class="venue-item__card">
        <div class="venue-item__card__glass non-selectable">
          @if (item.imageUrl && elementLoaded) {
            <img [ngSrc]="item.imageUrl" [alt]="'menu-item-photo' | translate" fill priority (error)="elementLoaded = false" />
          } @else {
            <nz-skeleton-element nzType="image" [nzActive]="true" [title]="'menu-item-photo' | translate"></nz-skeleton-element>
          }
          @if ((item?.price?.discountRate || 0) > 0) {
            <div class="discount">{{ item?.price?.discountRate }}&percnt;</div>
          }
        </div>
        <div class="venue-item__card__details">
          <h3 class="venue-item__card__details__title non-selectable">
            {{ item.localizedFields[currentLocale()].name | presentationcase }}
          </h3>
          <p class="venue-item__card__details__body non-selectable">
            {{ item.localizedFields[currentLocale()].description | presentationcase }}
          </p>
          <p class="venue-item__card__details__price non-selectable">
            <span class="price">{{ item.price.finalPrice | currency: 'RON' : 'symbol-narrow' : '1.2' : 'ro' }}</span>
            /&nbsp;{{ scaleAndAmount }}
          </p>
          <button
            class="venue-item__card__details__cta button-accent non-selectable clickable"
            type="button"
            [class.added-to-cart]="animationInProgress"
            tabindex="0"
            (click)="addToCart($event)">
            <span class="venue-item__card__details__cta__icon" [title]="'add-to-cart' | translate">
              <waytr-icon name="cart"></waytr-icon>
            </span>
          </button>
        </div>
      </div>
    }
  </ng-template>
</app-basic-component>
