import {
  CartItemModelResponseDto,
  PriceTypeModelResponseDto,
  SuborderModelResponseDto,
  SummaryProductResponseDto,
  SummaryResponseDto,
  VATGroupTypeModelResponseDto,
} from '../models';
import { DecimalPrecision2 } from './math.helper';

// Helper to initialize summary price object with default values
export function initializeSummaryPrice(): PriceTypeModelResponseDto {
  return {
    basePrice: 0,
    discountRate: 0,
    discountedPrice: 0,
    vatGroupId: -1,
    vatGroupPercentageValue: 0,
    vatPrice: 0,
    finalPrice: 0,
  };
}

// Retrieve the VAT percentage for the product, considering fallback to venue's VAT groups
export function getVatGroupPercentage(price: PriceTypeModelResponseDto, vatGroups: VATGroupTypeModelResponseDto[]): number {
  return (
    (price.vatGroupPercentageValue !== 0
      ? price.vatGroupPercentageValue
      : vatGroups.find(vatGroup => vatGroup.id === price.vatGroupId)?.percentageValue) || 0
  );
}

// Calculate individual product totals based on price, quantity, and VAT with decimal precision
export function calculateProductTotal(
  price: PriceTypeModelResponseDto,
  quantity: number,
  vatGroupPercentage: number,
): PriceTypeModelResponseDto {
  const basePrice = DecimalPrecision2.round(price.basePrice * quantity, 2);
  const discountedPrice = DecimalPrecision2.round(price.discountedPrice * quantity, 2);
  const vatPrice = DecimalPrecision2.round(discountedPrice * (vatGroupPercentage / 100), 2);
  const finalPrice = DecimalPrecision2.round(price.finalPrice * quantity, 2);

  return {
    basePrice,
    discountRate: price.discountRate,
    discountedPrice,
    vatGroupId: price.vatGroupId,
    vatGroupPercentageValue: vatGroupPercentage,
    vatPrice,
    finalPrice,
  };
}

export function calculateSummary(suborders: SuborderModelResponseDto[]): SummaryResponseDto {
  const summaryProducts: SummaryProductResponseDto[] = [];
  const summaryPrice: PriceTypeModelResponseDto = initializeSummaryPrice();

  suborders?.forEach(suborder => suborder.cartItems?.forEach(cartItem => processCartItem(cartItem, summaryProducts)));

  summaryProducts.forEach(productSummary => calculateProductTotals(productSummary, summaryPrice));

  finalizeSummaryPrice(summaryPrice);

  return {
    products: summaryProducts,
    price: summaryPrice,
  };
}

// Helper to process each cart item and update summary products
function processCartItem(cartItem: CartItemModelResponseDto, summaryProducts: SummaryProductResponseDto[]) {
  const { product, productPriceSnapshot } = cartItem;
  const { basePrice, discountRate, vatGroupId } = productPriceSnapshot;
  const quantity = cartItem.quantity;

  const existingEntry = summaryProducts.find(
    summaryProduct =>
      summaryProduct.product._id === product._id &&
      summaryProduct.product.price.basePrice === basePrice &&
      summaryProduct.product.price.discountRate === discountRate &&
      summaryProduct.product.price.vatGroupId === vatGroupId,
  );

  if (existingEntry) {
    existingEntry.quantity += quantity;
  } else {
    summaryProducts.push({
      product,
      total: { ...productPriceSnapshot },
      quantity,
    });
  }
}

// Helper to calculate total values for a single product and accumulate into summary price
function calculateProductTotals(productSummary: SummaryProductResponseDto, summaryPrice: PriceTypeModelResponseDto) {
  const { product, quantity, total } = productSummary;
  const { basePrice, discountedPrice, vatGroupPercentageValue } = product.price;

  total.basePrice = DecimalPrecision2.round(basePrice * quantity, 2);
  total.discountedPrice = DecimalPrecision2.round(discountedPrice * quantity, 2);
  total.vatPrice = DecimalPrecision2.round((total.discountedPrice * (vatGroupPercentageValue || 0)) / 100, 2);
  total.finalPrice = DecimalPrecision2.round(total.discountedPrice + total.vatPrice, 2);

  accumulateSummaryPrice(summaryPrice, total);
}

// Helper to accumulate totals for summary price
function accumulateSummaryPrice(summaryPrice: PriceTypeModelResponseDto, total: PriceTypeModelResponseDto) {
  summaryPrice.basePrice += total.basePrice;
  summaryPrice.discountedPrice += total.discountedPrice;
  summaryPrice.vatPrice += total.vatPrice;
  summaryPrice.finalPrice += total.finalPrice;
}

// Helper to finalize summary price by rounding to 2 decimal places
function finalizeSummaryPrice(summaryPrice: PriceTypeModelResponseDto) {
  summaryPrice.basePrice = DecimalPrecision2.round(summaryPrice.basePrice, 2);
  summaryPrice.discountedPrice = DecimalPrecision2.round(summaryPrice.discountedPrice, 2);
  summaryPrice.vatPrice = DecimalPrecision2.round(summaryPrice.vatPrice, 2);
  summaryPrice.finalPrice = DecimalPrecision2.round(summaryPrice.finalPrice, 2);
}
